import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { Mobile } from "src/components/device"
import { Carousel } from "src/components/carousel"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"
import { VideoPlayer } from "src/components/video-player"
import { Browser } from "src/components/device"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  Cell,
  Measure,
  HorizontalRuler,
  Headline,
  Paragraph,
  ParagraphTall,
  HeadlineHero,
  CellVerticalOffset,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: any
      subtitle: any
      we_designed_copy: any
      one_platform_title: any
      one_platform_copy: any
      inspiring_title: any
      inspiring_copy: any
      translating_copy: any
      scalable_title: any
      scalable_copy: any
      global_crisis_copy: any
      expertise: any
    }
    images: {
      powerof_hero_static: any
      user_journey_static: any
      experience_flow_static: any
      grid_1_brand_icon_static: any
      grid_1_type_static: any
      grid_1_color_palette_static: any
      grid_1_photography_static: any
      grid_2_hover_static: any
      grid_2_search_static: any
      grid_2_cards_static:any
      grid_2_social_static: any
      specs_static: any
      website_walkthrough_static: any
    }
    videos: {
      powerof_hero: any
      user_journey: any
      grid_1_type: any
      grid_1_photography: any
      grid_2_hover: any
      grid_2_search: any
      grid_2_cards: any
      grid_2_social: any
      website_walkthrough: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.powerof_hero}
            image={images.powerof_hero_static}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.we_designed_copy}</Paragraph>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.user_journey}
            image={images.user_journey_static}
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.one_platform_title}</Headline>
          <Paragraph>{copy.one_platform_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <ImgFluid image={images.experience_flow_static} />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.inspiring_title}</Headline>
          <Paragraph>{copy.inspiring_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid columns={{ bp1: "$2" }}>
            <ImgFluid image={images.grid_1_brand_icon_static} />
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_type}
              image={images.grid_1_type_static}
            />
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_photography}
              image={images.grid_1_photography_static}
            />
            <ImgFluid image={images.grid_1_color_palette_static} />
          </Grid>
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.translating_copy}</Paragraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }} >
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }} >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.grid_2_hover}
                image={images.grid_2_hover_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$3/4"
                video={videos.grid_2_search}
                image={images.grid_2_search_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                  ratio="$3/4"
                  video={videos.grid_2_cards}
                  image={images.grid_2_cards_static}
              />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }} >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.grid_2_social}
                image={images.grid_2_social_static}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.scalable_title}</Headline>
          <Paragraph>{copy.scalable_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <ImgFluid image={images.specs_static} />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.global_crisis_copy}</Paragraph>
      </Container>

      <Container>
        <Block>
          <Cell size="$large" style={{ backgroundColor: "#3936CF" }}>
            <Browser screen="#FFFFFF">
            <VideoLoopResponsive
                ratio="$16/9"
                video={videos.website_walkthrough}
                image={images.website_walkthrough_static}
              />
            </Browser>
          </Cell>
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "powerof" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
      subtitle
      we_designed_copy
      one_platform_title
      one_platform_copy
      inspiring_title
      inspiring_copy
      translating_copy
      scalable_title
      scalable_copy
      global_crisis_copy
      expertise
      }
      images {
        powerof_hero_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        user_journey_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        experience_flow_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_brand_icon_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_type_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_color_palette_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_photography_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_hover_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_search_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_cards_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_social_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        specs_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        website_walkthrough_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
      }
      videos {
        powerof_hero {
          src {
            src_large
            src_medium
            src_small
          }
        }
        user_journey {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_photography {
          src
        }
        grid_1_type {
          src
        }
        grid_2_hover {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_2_search {
          src
        }
        grid_2_cards {
          src
        }
        grid_2_social {
          src {
            src_large
            src_medium
            src_small
          }
        }
        website_walkthrough {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`
