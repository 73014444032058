import * as React from "react"
import { styled } from "src/stitches"
import { useIsDark } from "src/context/theme"

import * as styles from "./mobile.module.css"

const StyledMobile = styled("div", {
  flex: 1,
})

const Screen = styled("div", {
  width: "85%",
  overflow: "hidden",
  display: "grid",
  alignItems: "start",
  border: "none",
  position: "relative",
  "&:before": {
    content: '""',
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
    boxShadow: "inset 0 0 2px rgba(0,0,0,0.1)",
    zIndex: 10,
  },
})

export type TMobileProps = {
  screen?: string
  children?: React.ReactNode
}

export const Mobile = ({ children, screen, ...rest }: TMobileProps) => {
  const dark = useIsDark()
  const deviceStyles = dark
    ? {
        top: `${styles.top} ${styles.dark_top}`,
        bottom: `${styles.bottom} ${styles.dark_bottom}`,
        middle: `${styles.middle} ${styles.dark_middle}`,
      }
    : {
        top: `${styles.top} ${styles.light_top}`,
        bottom: `${styles.bottom} ${styles.light_bottom}`,
        middle: `${styles.middle} ${styles.light_middle}`,
      }
  return (
    <StyledMobile {...rest}>
      <div className={deviceStyles.top} />
      <div className={deviceStyles.middle}>
        <Screen css={{ backgroundColor: screen }}>{children}</Screen>
      </div>
      <div className={deviceStyles.bottom} />
    </StyledMobile>
  )
}
