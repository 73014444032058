import * as React from "react"
import { styled } from "src/stitches"
import { useIsDark } from "src/context/theme"

const StyledBrowser = styled("div", {
  width: "100%",
})

const StyledBrowserBar = styled("div", {
  width: "100%",
  position: "relative",
  paddingTop: "2.8125%",
  "& > svg": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
})

const SvgBar = styled("path", {
  fill: "#E8E8E8",
  variants: {
    dark: {
      true: {
        fill: "#F4F4F4",
      },
    },
    translucent: {
      true: {
        fill: "black",
        fillOpacity: 0.096,
      }
    },
  },
})

const SvgFillerLine = styled("rect", {
  fill: "#E8E8E8",
  variants: {
    dark: {
      true: {
        fill: "#F4F4F4",
      },
    },
  },
})

const Dot = styled("circle", {
  fill: "#FFF",
  variants: {
    dark: {
      true: {
        fill: "#000",
        opacity: 0.15,
      },
    },
    translucent: {
      true: {
        fill: "black",
        fillOpacity: 0.16,
        opacity: 1.0,
      }
    },
  },
})

const StyledSvg = styled("svg", {
  width: "100%",
  display: "block",
})
const Box = styled("div", {
  marginTop: "-1px",
})

const Screen = ({ bg, children }) => {
  return <Box style={{ backgroundColor: bg }}>{children}</Box>
}

// Translucent browser bar color control
const Translucent = true

export type TBrowserProps = {
  screen?: string
  translucent?: boolean
  dark?: boolean
  children?: React.ReactNode
}

export const Browser = ({ children, screen, translucent, ...rest }: TBrowserProps) => {
  const dark = useIsDark()

  return (
    <StyledBrowser {...rest}>
      <StyledBrowserBar>
        <StyledSvg
          viewBox="0 0 1280 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <SvgBar
            d="M0 9C0 4.02944 4.02944 0 9 0H1271C1275.97 0 1280 4.02944 1280 9V36H0V9Z"
            dark={dark}
            translucent={translucent}
          />
          <path
            d="M0.5 9C0.5 4.30558 4.30558 0.5 9 0.5H1271C1275.69 0.5 1279.5 4.30558 1279.5 9V35.5H0.5V9Z"
            stroke="black"
            strokeOpacity="0.1"
          />
          <Dot dark={dark} translucent={translucent} cx="18.5" cy="18.5" r="4.5" />
          <Dot dark={dark} translucent={translucent} cx="36.5" cy="18.5" r="4.5" />
          <Dot dark={dark} translucent={translucent} cx="54.5" cy="18.5" r="4.5" />
          <SvgFillerLine x="1" y="35" width="1278" height="1" dark={dark} />
        </StyledSvg>
      </StyledBrowserBar>
      <Screen bg={screen}>{children}</Screen>
    </StyledBrowser>
  )
}
